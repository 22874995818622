<template>
  <div class="h-full flex flex-col pb-0">
    <loader :loading="this.loading" :backdrop="true" />
    <div class="form-row">
      <div class="form-col">
        <label for="categories">main topic</label>
        <Dropdown
          id="categories"
          trackBy="intent_category_name"
          label="intent_category_name"
          :options="categories"
          :value="category"
          @input="setCategoryValue"
          :disabled="responseId !== ''"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="intents" :class="{ isDisabled: !this.category && responseId !== '' }">
          conversation detail
        </label>
        <Dropdown
          id="intents"
          trackBy="intent_name"
          :searchable="false"
          label="suggestion_text"
          :options="intents"
          :value="intent"
          @input="setIntentValue"
          :disabled="responseId !== '' || !this.category"
          objectMode
        />
      </div>
      <div class="form-col">
        <label for="sub_intent" :class="{ isDisabled: !this.intent || responseId !== '' }">
          next level detail
        </label>
        <Dropdown
          id="sub_intent"
          trackBy="intent_name"
          label="suggestion_text"
          :searchable="false"
          :options="subIntents"
          :value="subIntent"
          @input="setSubIntentValue"
          :disabled="responseId !== '' || !this.intent"
          objectMode
        />
      </div>
    </div>
    <div class="text-right">
      <button
        class="color-text focus:outline-none p-2"
        @click="toggleIsDisplay()"
        :disabled="isEdit"
        :class="{ disabledAddNew: isEdit }"
        v-if="category && intent && subIntent"
      >
        <icon name="plus" class="h-5 w-5 inline-block"></icon>
        <p class="inline-block p-1 hover:underline">add new</p>
      </button>
    </div>

    <div
      class="flex-1 flex-col overflow-auto p-2 focus:border-gray-700"
      v-if="category && intent && subIntent"
    >
      <detail-global-response
        v-for="response in responses.CommunityIntentUsesGlobalIntentResponse"
        :key="response.intent_response_id"
        :response="response"
        :selectedResponseId="selectedId"
        :disabled="responseId !== '' && response.intent_response_id !== responseId"
        @edit="getResponse"
      />
    </div>
    <div class="items-center" v-if="isDisplay">
      <div class="border border-gray-200">
        <div class="flex justify-between bg-gray-200">
          <label for="responseText" class="p-2 block">
            <span v-if="!isEdit">add new global response</span>
            <span v-else>edit global response</span>
          </label>

          <div class="flex">
            <div class="flex mr-32 my-1">
              <button
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border"
                :class="{ activeCatg: language === 0 }"
                @click="language = 0">
                english
              </button>
              <button
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border"
                :class="{ activeCatg : language === 1 }"
                @click="language = 1">
                spanish
              </button>
            </div>
            <div class="flex mr-4 my-1">
              <button
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border"
                :class="{ activeCatg: indicates_feature_not_present === 0 }"
                @click="indicates_feature_not_present = 0">
                negative
              </button>
              <button
                class="btn-responses-type bg-white text-gray-500 focus:outline-none py-1 px-4 font-semibold border color-border"
                :class="{ activeCatg : indicates_feature_not_present === 1 }"
                @click="indicates_feature_not_present = 1">
                positive
              </button>
            </div>
            <div class="flex">
              <label
                class="font-bold inline leading-10 mr-3"
                v-if="this.isDefaultResponse"
              >
                <icon
                  name="info"
                  class="h-6 w-6 inline color-text mb-px"
                ></icon>
                this is the default response
              </label>
            </div>
          </div>
        </div>

        <textarea v-if="language === 0"
          id="responseText"
          name="responseText"
          placeholder="type response..."
          class="resize-none w-full focus:outline-none p-2 bg-white"
          rows="3"
          v-model="responseText"
        />
        <textarea v-else
          id="spanishResponseText"
          name="spanishResponseText"
          placeholder="type response..."
          class="resize-none w-full focus:outline-none p-2 bg-white"
          rows="3"
          v-model="spanishResponseText"
        />

        <div class="block text-right leading-3">
          <button
            class="btn-primary btn-transparent float-left"
            @click.stop="cancel"
          >
            cancel
          </button>
          <button
            v-if="isEdit && !this.isDefaultResponse"
            class="btn-danger"
            @click="toggleIsDelete()"
          >
            delete response
          </button>
          <button v-if="!isEdit" class="btn-primary" @click="save()">
            add response
          </button>
          <button v-else class="btn-primary ml-5" @click="onEdit()">
            save response
          </button>
        </div>
      </div>
      <span v-if="hasError('responseText')" class="form-error">
        {{ showError('responseText') }}
      </span>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import AuthMixin from '@/components/auth/AuthMixin'
import ModalNavigation from '@/mixins/ModalNavigation'
import GlobalResponsesMixin from '@/components/digital_human/GlobalResponsesMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import Loader from '@/components/ui/Loader'
import Dropdown from '@/components/ui/Dropdown'
import DetailGlobalResponse from '@/components/digital_human/DetailGlobalResponse'
import Icon from '@/components/ui/Icon'
import EventBus from '@/utils/EventBus'

export default {
  mixins: [AuthMixin, ModalNavigation, NotifyMixin, GlobalResponsesMixin],
  components: {
    Icon,
    Dropdown,
    DetailGlobalResponse,
    Loader,
  },
  computed: {
    intentName: function() {
      return this.subIntent && this.subIntent?.intent_name !== 'general'
        ? this.subIntent?.intent_name
        : this.intent?.intent_name
    },
    confirmationMessage: function(){
      return this.number_of_communities == 0
      ? `Are you sure you want to delete this response?`
      : (this.number_of_communities == 1
          ? `Are you sure you want to delete this response? There is ${this.number_of_communities} community using it`
          : `Are you sure you want to delete this response? There are ${this.number_of_communities} communities using it`
        )
    },
    ...mapGetters({
      backModal: 'modals/getLastModal',
    }),
  },
  data() {
    return {
      responseId: '',
      communityIntentId: '',
      responseText: '',
      spanishResponseText: '',
      isDisplay: false,
      isEdit: false,
      isDelete: false,
      isDefaultResponse: false,
      defaultResponseId: '',
      defaultResponse: false,
      indicates_feature_not_present: 0,
      language: 0,
      selectedId: '',
      number_of_communities: 0,
    }
  },
  methods: {
    disableResponses(response, responseId) {
      return responseId !== '' && response && response.intent_response_id !== responseId;
    },
    fetchCategories() {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('categories')
        .then((response) => {
          this.loadCategories(response)
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => this.setLoading(false))
    },
    fetchIntents(categoryName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('intents', { category: categoryName })
        .then((response) => {
          this.loadIntents(response)
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => this.setLoading(false))
    },
    fetchSubIntents(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('subIntentsDetail', { intent: intentName })
        .then((response) => {
          this.loadSubIntents(response)
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => this.setLoading(false))
    },
    fetchResponses(intentName) {
      this.setLoading(true)
      this.$dhDataProvider
        .getList('allGlobalIntentResponses', { intent: encodeURIComponent(intentName) })
        .then((response) => {
          this.loadResponses(response)
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.setLoading(false)
          this.isDisplay = false
        })
    },
    setCategoryValue(value) {
      this.cleanState('category')
      this.selectedId = ''
      this.isDisplay = false
      this.isEdit = false
      if (!value) return
      this.setCategory(value)
      this.fetchIntents(value?.description)
      this.isDisplay = false
    },
    setIntentValue(value) {
      this.cleanState('intent')
      this.selectedId = ''
      this.isDisplay = false
      this.isEdit = false
      if (!value) return
      this.setIntent(value)
      this.fetchResponses(value?.intent_name)
      this.fetchSubIntents(value?.intent_name)
    },
    setSubIntentValue(value) {
      this.cleanState('subIntent')
      this.selectedId = ''
      this.isDisplay = false
      this.isEdit = false
      if (!value) return
      const subIntentValue =
        value?.intent_name === 'general' ? this.intent : value
      this.setSubIntent(value)
      this.fetchResponses(subIntentValue.intent_name)
    },
    toggleIsDisplay() {
      this.responseId = ''
      this.communityIntentId = ''
      this.responseText = ''
      this.language = 0
      this.spanishResponseText = ''
      this.defaultResponse = false
      this.isDefaultResponse = false
      this.isDelete = false
      this.indicates_feature_not_present = 0
      if(this.isEdit) {
        this.isEdit = false
      }
      this.isDisplay = !this.isDisplay
    },
    toggleIsDelete() {
      this.isDisplay = false
      this.isDelete = true
      EventBus.emit('show-modal-confirmation', {
        id: 'dh-manage-global-responses',
        title: 'global response catalog',
        showConfirmation: true,
        confirmationMessage: this.confirmationMessage,
        confirmationType: 'warning',
      })
      EventBus.once('confirm-dh-manage-global-responses', () => {
        this.onDelete()
      })
      EventBus.on('cancel-confirmation-dh-manage-global-responses', () => {
        this.cancel()
      })
    },
    cancel() {
      this.isDisplay = false;
      this.validator.errorsBag = [];
      this.selectedId = '';
      this.responseId = '';
      this.isEdit = false;
    },
    save() {
      if (this.validator.passes()) {
        this.setLoading(true)

        this.$dhDataProvider
          .create('globalResponse', {
            data: {
              customer_id: '27ff4e7f-533e-4f5d-bedc-360aca35056b', // Ask if we need to change this
              intent_name:
                this.subIntent && this.subIntent?.intent_name !== 'general'
                  ? this.subIntent?.intent_name
                  : this.intent?.intent_name,
              response_text: this.responseText,
              spanish_response_text: this.spanishResponseText,
              default_response: this.defaultResponse,
              indicates_feature_not_present: this.indicates_feature_not_present,
            },
          })
          .then(() => {
            this.isDisplay = !this.isDisplay
            this.responseText = ''
            this.spanishResponseText = ''
            this.selectedId = ''
            this.language = 0
            this.notifySuccess('The response was created successfully')
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.fetchResponses(this.intentName)
            this.toggleIsDisplay()
            this.setLoading(false)
          })
      }
    },
    onEdit() {
      if (this.validator.passes()) {
        this.setLoading(true)
        this.$dhDataProvider
          .update('editResponse', {
            id: this.responseId,
            data: {
              community_id: this.community?.id,
              response_text: this.responseText,
              spanish_response_text: this.spanishResponseText,
              intent_name:
                this.subIntent?.intent_name !== 'general' && this.subIntent
                  ? this.subIntent?.intent_name
                  : this.intent?.intent_name,
              default_response: this.defaultResponse,
              indicates_feature_not_present: this.indicates_feature_not_present,
            },
          })
          .then(() => {
            this.selectedId = ''
            this.notifySuccess('The response was updated successfully')
          })
          .catch((err) => this.notifyError(err.message))
          .finally(() => {
            this.fetchResponses(this.intentName)
            this.toggleIsDisplay()
            this.setLoading(false)
            this.responseId = '';
          })
      }
    },
    onDelete() {
      this.setLoading(true)
      this.$dhDataProvider
        .delete('globalResponse', { id: this.responseId })
        .then(() => {
          this.selectedId = ''
          this.isDisplay = false;
          this.validator.errorsBag = [];
          this.isEdit = false;
          this.notifySuccess('The global response was successfully deleted')
          this.isDelete = false
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.fetchResponses(this.intentName)
          this.responseId = '';
          this.setLoading(false)
        })
    },
    getResponse(responseId, indicates_feature_not_present, number_of_communities) {
      this.indicates_feature_not_present = indicates_feature_not_present
      this.responseId = responseId
      this.selectedId = responseId
      this.number_of_communities = number_of_communities

      this.setLoading(true)
      this.$dhDataProvider
        .get('allGlobalResponsesByResponseId', { responseId: responseId })
        .then((res) => {
          this.responseText = res.response_text
          this.spanishResponseText =  res.spanish_response_text
          this.defaultResponseId = res.default_response_id
          this.defaultResponse = false
          this.communityIntentId = ''
          responseId == this.defaultResponseId
            ? (this.isDefaultResponse = true)
            : (this.isDefaultResponse = false)
          if (this.responseText.trim() !== '') this.validator.errorsBag = []
        })
        .catch((err) => this.notifyError(err.message))
        .finally(() => {
          this.isDisplay = true
          this.isEdit = true
          this.isDelete = false
          this.setLoading(false)
        })
    },
  },
  validations: {
    responseText: 'required | is_empty',
  },
  created() {
    this.initValidator()
    this.fetchCategories()
    if (this.category && this.intent && this.subIntent) {
      const value =
        this.subIntent?.intent_name === 'general' ? this.intent : this.subIntent
      this.fetchResponses(value.intent_name)
    }
    this.validator.extend('is_empty', function(value) {
      return !value.match(/^\s+$/)
    })
    this.validator.setCustomErrorMessages({
      'responseText.is_empty': 'this field is empty.',
    })
  },
  unmounted() {
    if (!this.backModal) {
      this.cleanState('category')
    }
  },
}
</script>

<style scoped>
.color-text{
  color: var(--highlightColor500);
}
.color-border{
   border-color: var(--highlightColor500);
}
.active {
  color: var(--highlightColor500);
}
.isDisabled {
  @apply text-gray-400;
}
.disabledAddNew {
  @apply text-gray-400 cursor-default;
}
.btn-responses-type {
  width: 20;
  height: 10;
}
.activeCatg {
  background-color: var(--highlightColor500);
  @apply text-white border-transparent;
}
</style>

<template>
  <div
    class="pb-2 border border-gray-100 border-1 mb-3 p-4 container-response cursor-pointer"
    @click="handlerClick"
    :class="{ selectedResponse: isActive(response.intent_response_id), isDisabled: disabled }">
    <div class="flex">
      <div class="flex justify-between w-full mb-6">
        <span class="w-2 h-2 rounded-full bg-green-200 m-2" v-if="response.indicates_feature_not_present"></span>
        <span class="w-2 h-2 rounded-full bg-red-200 m-2"   v-else></span>
        <div class="flex flex-1 flex-col">
          <p strong class="flex-1 mx-2 m-px font-bold">
          {{ response.intentResponse_response_text }}
          </p>
          <span 
            strong class="flex-1 mx-2 m-px text-gray-600 mt-1"
            v-if="response.spanish_response_text"
          >
            {{ response.spanish_response_text }}
            <p v-if="response.status=='outDatedTranslated'" class="mt-1 text-yellow-300">
              outdated translation
            </p>
          </span>
          <p 
            v-else
            strong class="mx-2 m-px text-red-600"
          >
              no spanish translation
          </p>
        </div>
      </div>
      <div class="flex"> 
        <p 
        v-if="response.intent_response_id == response.default_response_id" 
        class="font-black text-green-500  text-2xl mr-3 -mt-1">
         <icon name="checkmark" class="h-5 w-5 inline-block "></icon></p>
        <span class="border-2 h-8 w-8 color-text color-border rounded-full items-center flex justify-center">
            <p class="text-sm">{{response.number_of_communities}}</p>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import GlobalResponsesMixin from '@/components/digital_human/GlobalResponsesMixin'
import Icon from '@/components/ui/Icon'

export default {
  mixins: [GlobalResponsesMixin],
  components: {
    Icon
  },
  props: {
    response: {
      type: Object,
      default() {
        return {}
      },
    },
    selectedResponseId: {
      type: String,
      default: '',
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: true,
    },
  },
  emits: ['edit'],
  methods: {
    isActive (id) {
      if (this.$props.selectedResponseId === id) return true;
      return false; 
    },
    handlerClick () {
      if (!this.$props.disabled) {
        this.$emit('edit', this.$props.response.intent_response_id, this.$props.response.indicates_feature_not_present, this.$props.response.number_of_communities)
      }
      return;
    },
  },
}
</script>

<style scoped>
  .color-text{
    color: var(--highlightColor500);
  }
  .color-border{
    border-color: var(--highlightColor500);
  }
  .container-response:hover{
    background-color: #ebf8ff;
  }
  .selectedResponse {
    background-color: #ebf8ff;
  }
  .isDisabled {
    opacity: 0.5;
    cursor: default;
  }
</style>
